// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// Import AOS styles
import 'aos/dist/aos.css';

// Import AOS JavaScript
import AOS from 'aos';

// Initialize AOS
AOS.init({
    duration: 1500
});


//create animated header
const words = ["teachers", "parents", "policy makers", "builders", "architects", "school leaders"];
let currentIndex = 0;
const changingWord = document.getElementById("cycle");

function changeWord() {
    changingWord.classList.add("fade-out");

    // Wait for the fade-out transition to complete
    setTimeout(() => {
        // Update the text content
        currentIndex = (currentIndex + 1) % words.length;
        changingWord.textContent = words[currentIndex];

        // Remove fade-out, add fade-in
        changingWord.classList.remove("fade-out");
        changingWord.classList.add("fade-in");

        // After the fade-in transition completes, remove the fade-in class
        setTimeout(() => {
            changingWord.classList.remove("fade-in");
        }, 500); // This should match the fade-in duration
    }, 500); // This should match the fade-out duration
}

setInterval(changeWord, 3000); // Change the word every 3 seconds


const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

